import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { Button } from "clutch/src/Button/Button.jsx";
import { ButtonLoadingSpinner } from "clutch/src/LoadingSpinner/LoadingSpinner.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { accountRefs } from "@/app/account.refs.mjs";
import { appURLs, GAME_SHORT_NAMES } from "@/app/constants.mjs";
import {
  CLICK_ACTIONS,
  EventStatus,
  IMPRESSION_TARGETS,
} from "@/feature-battles/constants.mjs";
import {
  getEventStatus,
  useParticipantInfo,
} from "@/feature-battles/hooks.mjs";
import { Card } from "@/feature-battles/Shared.jsx";
import { useBattlesAnalytics } from "@/feature-battles/use-battles-analytics.mjs";
import { useOverlayJoinEvent } from "@/feature-battles/use-join-event.mjs";
import {
  filterByGameSymbol,
  formatParticipantCount,
  getCurrentEvents,
  sortByFeatured,
} from "@/feature-battles/utils.mjs";
import BlitzLogo from "@/inline-assets/blitz-logo.svg";
import CheckIcon from "@/inline-assets/check.svg";
import BlitzWordmark from "@/inline-assets/wordmark-with-gg.svg";
import { useGameSymbol } from "@/util/game-route.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const PromoCard = styled(Card)`
  flex-direction: column !important;
  align-items: unset !important;

  .summary {
    flex-direction: column;
  }

  img {
    width: calc(var(--sp-15) + var(--sp-0_5));
    height: calc(var(--sp-15) + var(--sp-0_5));
  }

  .mini {
    display: flex;
    gap: var(--sp-1_5);
  }

  .uppercase {
    text-transform: uppercase;
  }

  .note {
    text-align: center;
  }
`;

const OptedInButton = styled(Button)`
  background-color: var(--turq-15) !important;
  color: var(--turq) !important;

  pointer-events: none !important;

  span {
    display: flex;
  }
`;

const PoweredByLabel = styled("div")`
  display: flex;
  align-items: center;

  margin-top: var(--sp-4);

  svg {
    width: auto;
  }

  .logo {
    height: var(--sp-4);
  }

  .wordmark {
    height: var(--sp-2);
  }
`;

const MinimizedPromo = styled("div")`
  align-items: center;

  img {
    width: var(--sp-9);
    height: var(--sp-9);
  }
`;

// TODO: handle non-league games if we want to reuse this exact design
const BattlesLoadingScreenPromo = ({ LoLLiveClient, minimized }) => {
  const {
    battles: { eventMap, eventParticipantInfo, userOptIns },
    settings: {
      battles: { hideEventPromos },
    },
  } = useSnapshot(readState);

  const [copyVariation, setCopyVariation] = useState(null);
  const { t } = useTranslation();

  const eventList = getCurrentEvents(eventMap);
  const featuredEvent = eventList
    .filter(filterByGameSymbol)
    .filter(
      ({ startAt, endAt, featurePriority }) =>
        getEventStatus(new Date(), startAt, endAt) !== EventStatus.ENDED &&
        (featurePriority || featurePriority === 0),
    )
    .sort((a, b) => {
      const aOptedIn = Boolean(userOptIns?.[a.id]?.optedIn);
      const bOptedIn = Boolean(userOptIns?.[b.id]?.optedIn);

      if (aOptedIn && !bOptedIn) return 1;
      if (!aOptedIn && bOptedIn) return -1;
      return sortByFeatured(a, b);
    })?.[0];

  const participantInfo = useParticipantInfo(
    featuredEvent?.id,
    eventParticipantInfo,
    userOptIns,
  );

  const summonerName =
    LoLLiveClient.activePlayer?.summonerName || t("lol:summoner", "Summoner");

  const pointDownEmoji = "👇";

  const { isLoggedIn } = useSnapshot(accountRefs);

  const optedIn = Boolean(userOptIns?.[featuredEvent?.id]?.optedIn);

  const copyVariations = useMemo(() => {
    return {
      0: {
        pending: {
          title:
            t(
              "battles:loadingScreenPromo.summonerInvite",
              "{{summonerName}}, you're invited to participate in a Battles Event!",
              {
                summonerName: summonerName,
              },
            ) +
            " " +
            pointDownEmoji,
          note: t(
            "battles:loadingScreenPromo.joiningWontTakeAway",
            "Joining won't take you from your current game.",
          ),
          trackingKey: "pending-1",
        },
        joined: {
          title: t(
            "battles:loadingScreenPromo.joinSuccess",
            "You’ve been successfully entered into the event!",
          ),
          note: t(
            "battles:loadingScreenPromo.joiningWontTakeAway",
            "Joining won't take you from your current game.",
          ),
          trackingKey: "joined-4",
        },
      },
      1: {
        pending: {
          title: null,
          note: null,
          trackingKey: "pending-2",
        },
        joined: {
          title: null,
          note: null,
          trackingKey: "joined-2",
        },
      },
      2: {
        pending: {
          title: t(
            "battles:loadingScreenPromo.joinFreeAndWinPrizes",
            "Join for free and win a piece of {{prizePool}} in prizes!",
            {
              prizePool: featuredEvent?.totalPrizePool,
            },
          ),
          note: t(
            "battles:loadingScreenPromo.joiningWontTakeAway",
            "Joining won't take you from your current game.",
          ),
          trackingKey: "pending-3",
        },
        joined: {
          title: t(
            "battles:loadingScreenPromo.joinSuccess",
            "You’ve been successfully entered into the event!",
          ),
          note: t(
            "battles:loadingScreenPromo.joiningWontTakeAway",
            "Joining won't take you from your current game.",
          ),
          trackingKey: "joined-4",
        },
      },
    };
  }, [featuredEvent, summonerName, t]);

  useEffect(() => {
    if (!featuredEvent) return;

    setCopyVariation(
      copyVariations[
        Math.floor(Math.random() * Object.keys(copyVariations).length)
      ][optedIn ? "joined" : "pending"],
    );
  }, [copyVariations, featuredEvent, optedIn]);

  if (
    hideEventPromos ||
    !isLoggedIn ||
    !featuredEvent ||
    !copyVariation ||
    // Don't show for events w/ fee
    featuredEvent.kryptoniteProductId ||
    featuredEvent.entryFee
  )
    return null;

  return (
    <LoadingScreenPromo
      event={featuredEvent}
      copy={copyVariation}
      participantInfo={participantInfo}
      optedIn={optedIn}
      minimized={minimized}
    />
  );
};

function LoadingScreenPromo({
  event,
  copy,
  participantInfo,
  optedIn,
  minimized,
}) {
  const {
    settings: { lastLoggedInIdByGame },
  } = useSnapshot(readState);

  const { sendClickEvent, impressionRef } = useBattlesAnalytics(
    event.id,
    `${IMPRESSION_TARGETS.loadingScreen} ${copy.trackingKey}`,
  );
  const { isLoading, postJoinEvent, hasJoined } = useOverlayJoinEvent(event.id);

  const gameSymbol = useGameSymbol();
  const {
    i18n: { language },
    t,
  } = useTranslation();

  const onSubmit = async () => {
    const gameName = GAME_SHORT_NAMES[gameSymbol];
    const gameAccountKey = lastLoggedInIdByGame[gameName];
    await postJoinEvent({
      gameAccountKey,
      gameSymbol,
    });
  };

  const dotSign = "•";

  return (
    <div ref={impressionRef}>
      {!minimized ? (
        <>
          <PromoCard className="flex gap-3">
            <div className="flex gap-6 summary">
              {copy.title && <p className="type-subtitle2">{copy.title}</p>}
              <div className="flex gap-4">
                <img
                  {...(event.nativeAdUnit?.logoImg
                    ? {
                        src: `${appURLs.BATTLES_CDN}/${event.nativeAdUnit.logoImg}`,
                      }
                    : {
                        // fallback to default img
                      })}
                />
                <div>
                  <p className="type-body2">{event.displayName}</p>
                  <p className="type-body2 shade1">
                    {t(
                      "battles:prizePoolTotalPrize",
                      "{{totalPrize}} Prize Pool",
                      {
                        totalPrize: event.totalPrizePool,
                      },
                    )}
                  </p>
                  <p className="type-mini shade1 mini">
                    <span>
                      {participantInfo?.capacity
                        ? t(
                            "battles:joined.cap",
                            "{{participantCount, number}}/{{capacity, number}} Joined",
                            {
                              participantCount: participantInfo.count,
                              capacity: participantInfo.capacity,
                            },
                          )
                        : t(
                            "battles:joined.nocap",
                            "{{participantCount}} Joined",
                            {
                              participantCount: formatParticipantCount(
                                language,
                                participantInfo.count,
                              ),
                            },
                          )}
                    </span>
                    <span>{dotSign}</span>
                    <span>{t("battles:freeEntry", "Free Entry")}</span>
                  </p>
                </div>
              </div>
              {optedIn || hasJoined ? (
                <OptedInButton>
                  <CheckIcon />
                  {t("common:joined", "Joined")}
                </OptedInButton>
              ) : (
                <Button
                  className="cta"
                  emphasis="high"
                  onClick={() => {
                    sendClickEvent(CLICK_ACTIONS.join);
                    onSubmit();
                  }}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <ButtonLoadingSpinner />
                  ) : (
                    t("battles:priorityJoin", "Priority Join")
                  )}
                </Button>
              )}
            </div>
            {copy.note && (
              <p className="type-body2 shade1 type-caption note">{copy.note}</p>
            )}
          </PromoCard>
          <PoweredByLabel className="type-overline">
            {t("common:poweredBy", "Powered by")}
            <BlitzLogo className="logo" />
            <BlitzWordmark className="wordmark" />
          </PoweredByLabel>
        </>
      ) : !(optedIn || hasJoined) ? (
        <MinimizedPromo className="flex gap-3">
          <img
            {...(event.nativeAdUnit?.logoImg
              ? {
                  src: `${appURLs.BATTLES_CDN}/${event.nativeAdUnit.logoImg}`,
                }
              : {
                  // fallback to default img
                })}
          />
          <p className="type-form--button shade0">
            {t("battles:winInPrizes", "Win {{totalPrize}} in prizes!", {
              totalPrize: event.totalPrizePool,
            })}
          </p>
          <Button
            className="cta"
            emphasis="high"
            onClick={() => {
              sendClickEvent(CLICK_ACTIONS.join);
              onSubmit();
            }}
            disabled={isLoading}
          >
            {isLoading ? <ButtonLoadingSpinner /> : t("battles:join", "Join")}
          </Button>
        </MinimizedPromo>
      ) : null}
    </div>
  );
}

export default BattlesLoadingScreenPromo;
